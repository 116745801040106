
const  menu = document.querySelector('.header__menu');
const  hamburger = document.querySelector('.hamburger');
const  menuItem = document.querySelectorAll('.menu__item, .menu__link');

window.addEventListener('DOMContentLoaded', () => {
    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('hamburger--active');
        menu.classList.toggle('header__menu--active');
    });
    menuItem.forEach(item => {
        item.addEventListener('click', function () {
        menu.classList.remove('header__menu--active');
        hamburger.classList.remove('hamburger--active');
    })
    });
});

$(window).on('load', function () {
    $().fancybox();

    $('a[href^="#"]:not(a.popup):not(a.more-btn)').on('click', function() {
        let href = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(href).offset().top
        });
        return false;
    }); 


    $('.pile-slider').slick({
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.portfolio-slider').slick({
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
    });

    $('.object__photos').slick({
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.media__photos').slick({
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
    ]
    });
    $('.media__videos').slick({
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
    ]
    });
    $('.company-slider').slick({
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
    ]
    });


if($(window).width() < 992){
  $(".steps__item").on('click', function() { 
      $(this).toggleClass('steps__item--active');
      if($(this).find('.steps__item-content').text() !== ''){//Если текст есть
          $(this).find('.steps__item-content').slideToggle();// плавно спускаем вниз текст ответа на вопрос
      }
  });
}else{        
        //Табы
        $('ul.steps__list').on('click', 'li:not(.steps__item--active)', function() {
            $(this)
                .addClass('steps__item--active').siblings().removeClass('steps__item--active')
                .closest('div.steps__content').find('div.steps__item-content').removeClass('steps__item-content--active').eq($(this).index()).addClass('steps__item-content--active');
        });
    }
});